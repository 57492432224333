import React, { useEffect, useMemo } from 'react';
import storeJS from 'store';

import { useMutationMeUpdate } from 'gql/mutations/me';
import { mutationAppLogOut } from 'gql/mutations/app';
import { useQueryLocalApp } from 'gql/queries/local';
import { useQueryMe } from 'gql/queries/me';
import useSiteContext from 'hooks/useSiteContext';
import { reportError } from 'lib/tracking';

import('./Vendors');
const App = React.lazy(() => import('./App'));

const AppContainer = () => {
  const {
    data: {
      app: { isAuthenticated },
    },
  } = useQueryLocalApp();
  const { error, isLoading } = useQueryMe({
    skip: !isAuthenticated,
  });
  useEffect(() => {
    function keyListener(event) {
      if (event.keyCode === 32 && event.target.href) {
        event.target.click();
      }
    }
    document.addEventListener('keydown', keyListener);
    return () => document.removeEventListener('keydown', keyListener);
  });
  useEffect(() => {
    (async function asyncEffect() {
      try {
        if (error) {
          await mutationAppLogOut();
        }
      } catch (err) {
        reportError('app', 'init', err);
      }
    })();
  }, [error]);
  const { expoPushToken, isEditionDisabled, isInTeams, isGAR } =
    useSiteContext();
  const mutationMeUpdate = useMutationMeUpdate();
  useEffect(() => {
    async function updateMe() {
      if (isAuthenticated && expoPushToken) {
        try {
          await mutationMeUpdate({
            pushToken: expoPushToken,
          });
        } catch {
          //
        }
      }
    }
    updateMe();
  }, [expoPushToken, isAuthenticated, mutationMeUpdate]);
  const wasGAR = useMemo(
    () => !isAuthenticated && !!storeJS.get('garsecurelogout'),
    [isAuthenticated],
  );
  useEffect(() => {
    if (isAuthenticated && isGAR) {
      const exdate = new Date();
      exdate.setDate(exdate.getDate() + 1);
      storeJS.set('garsecurelogout', true, exdate.toUTCString());
    }
  }, [isAuthenticated, isGAR]);
  return (
    <App
      isAuthenticated={isAuthenticated}
      isEditionDisabled={isEditionDisabled}
      isLoading={isAuthenticated && isLoading}
      isInTeams={isInTeams}
      wasGAR={wasGAR}
    />
  );
};

export default AppContainer;
