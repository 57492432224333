import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import 'dayjs/locale/it';

dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(isoWeek);
