import { InMemoryCache } from '@apollo/client';

import {
  appVar,
  editorVar,
  expertVar,
  studyVar,
  playVar,
  testVar,
  testReviewVar,
} from './local';

const memoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        app: {
          read() {
            return appVar();
          },
        },
        editor: {
          read() {
            return editorVar();
          },
        },
        expert: {
          read() {
            return expertVar();
          },
        },
        study: {
          read() {
            return studyVar();
          },
        },
        play: {
          read() {
            return playVar();
          },
        },
        test: {
          read() {
            return testVar();
          },
        },
        testReview: {
          read() {
            return testReviewVar();
          },
        },
        course(_, { args, toReference }) {
          return toReference({
            __typename: 'Course',
            id: args.courseId,
          });
        },
        chapter(_, { args, toReference }) {
          return toReference({
            __typename: 'Chapter',
            id: args.chapterId,
          });
        },
        question(_, { args, toReference }) {
          return toReference({
            __typename: 'Question',
            id: args.questionId,
          });
        },
        game(_, { args, toReference }) {
          return toReference({
            __typename: 'Game',
            id: args.gameId,
          });
        },
        comment(_, { args, toReference }) {
          return toReference({
            __typename: 'Comment',
            id: args.commentId,
          });
        },
        commentReply(_, { args, toReference }) {
          return toReference({
            __typename: 'CommentReply',
            id: args.CommentReplyId,
          });
        },
        notificationsMe: {
          keyArgs: false,
          merge(existing = [], incoming) {
            const existingNotificationIds = existing.map((e) => e.id);
            const newIncomingNotifications = incoming.filter(
              (incomingNotification) =>
                !existingNotificationIds.includes(incomingNotification.id),
            );
            return [...existing, ...newIncomingNotifications];
          },
        },
        studyAnswers: {
          keyArgs: ['questionId'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

export default memoryCache;
