import { gql } from '@apollo/client';

export const ME_DATA = gql`
  fragment MeData on Account {
    id
    organizationId
    planId
    isVerified
    tags
    wantsMarketing
    firstName
    lastName
    username
    email
    picture
    language
    sector
    role
    institution
    branch
    addressLine1
    addressLine2
    city
    zip
    state
    country
    company
    vat
    referralCode
    registeredAt
    emailNotifications
    pushNotifications
    pushToken
    job
    biography
    twitter
    linkedin
    metadata
    flags
    wantsAI
  }
`;

export const PROFILE_DATA = gql`
  fragment ProfileData on Profile {
    id
    firstName
    lastName
    picture
    job
    biography
    twitter
    linkedin
  }
`;

export const REFERRAL_DATA = gql`
  fragment ReferralData on Referral {
    id
    accountId
    username
    isVerified
  }
`;

export const PLAN_DATA = gql`
  fragment PlanData on Plan {
    id
    name
  }
`;

export const ORGANIZATION_DATA = gql`
  fragment OrganizationData on Organization {
    id
    name
    picture
    forceSSO
    allowGoogleSSO
    allowMicrosoftSSO
    supportEmail
    settings
  }
`;

export const AUTH_METHOD_DATA = gql`
  fragment AuthMethodData on AuthMethod {
    id
    strategy
    strategySpecifier
  }
`;

export const FOLDER_DATA = gql`
  fragment FolderData on Folder {
    id
    accountId
    title
  }
`;

export const ME_FULL_DATA = gql`
  fragment MeFullData on Account {
    ...MeData
    plan {
      ...PlanData
    }
    organization {
      ...OrganizationData
    }
    authMethods {
      ...AuthMethodData
    }
  }
  ${ME_DATA}
  ${PLAN_DATA}
  ${ORGANIZATION_DATA}
  ${AUTH_METHOD_DATA}
`;

export const TAG_DATA = gql`
  fragment TagData on Tag {
    id
    title
  }
`;

export const COURSE_DATA = gql`
  fragment CourseData on Course {
    id
    accessCode
    title
    introduction
    isLinear
    masteryThreshold
    themeBackground
    isExam
    isLinearExam
    examStartTime
    examStopTime
    examDurationMinutes
    examInRandomOrder
    nQuestionsByExam
    examInstructions
    isExamReviewEnabled
    isStudentCollaborationEnabled
    isPublic
    category
    language
    destination
    classes
    institution
    nLikes
    isLiked
    level
    isPinned
    folderId
    moodle
    lti
    msTeams
    gar
    tags {
      ...TagData
    }
    createdAt
    lastConnection
    deadline
    canEditDeadline
  }
  ${TAG_DATA}
`;

export const PUBLIC_COURSE_DATA = gql`
  fragment PublicCourseData on PublicCourse {
    id
    accessCode
    title
    introduction
    themeBackground
    isPublic
    category
    language
    destination
    classes
    institution
    nLikes
    isLiked
    level
    tags {
      ...TagData
    }
    createdAt
  }
  ${TAG_DATA}
`;

export const CHAPTER_DATA = gql`
  fragment ChapterData on Chapter {
    id
    courseId
    order
    title
    isHidden
    isOffline
    onlineTimestamp
    offlineTimestamp
    isLinked
  }
`;

export const PUBLIC_CHAPTER_DATA = gql`
  fragment PublicChapterData on PublicChapter {
    id
    courseId
    order
    title
  }
`;

export const QUESTION_DATA = gql`
  fragment QuestionData on Question {
    id
    courseId
    chapterId
    order
    type
    tags
    title
    content
    authorId
    isAwaitingApproval
    grade
    studentFeedback
  }
`;

export const FILE_DATA = gql`
  fragment FileData on File {
    id
    courseId
    chapterId
    name
    link
  }
`;

export const COMMENT_DATA = gql`
  fragment CommentData on Comment {
    id
    accountId
    questionId
    studyAnswerId
    content
    isClosed
    isRead
    createdAt
    updatedAt
  }
`;

export const COMMENT_REPLY_DATA = gql`
  fragment CommentReplyData on CommentReply {
    id
    accountId
    commentId
    content
    isRead
    createdAt
    updatedAt
  }
`;

export const GAME_DATA = gql`
  fragment GameData on Game {
    id
    courseId
    chapterId
    type
    title
  }
`;

export const STUDY_ANSWER_DATA = gql`
  fragment StudyAnswerData on StudyAnswer {
    id
    answer
    result
    solution
  }
`;

export const STUDY_STATS_DATA = gql`
  fragment StudyStatsData on StudyStats {
    id
    attempted
    succeeded
    rate
    mastery
  }
`;

export const COURSE_STATS_DATA = gql`
  fragment CourseStatsData on CourseStats {
    id
    students
  }
`;

export const PLAY_STATS_DATA = gql`
  fragment PlayStatsData on PlayStats {
    id
    ranking
    rank
    of
  }
`;

export const GROUP_DATA = gql`
  fragment GroupData on Group {
    id
    courseId
    title
  }
`;

export const PARTICIPANT_DATA = gql`
  fragment ParticipantData on Participant {
    id
    accountId
    courseId
    groupId
    firstName
    lastName
    picture
    username
    email
    level
    isActive
    metadata
  }
`;

export const PARTICIPANT_LIGHT_DATA = gql`
  fragment ParticipantLightData on Participant {
    id
    firstName
    lastName
    picture
    level
    isActive
    lastConnection
  }
`;

export const COURSE_LIGHT_DATA = gql`
  fragment CourseLightData on Course {
    ...CourseData
    owner {
      ...ParticipantData
    }
    courseStats {
      ...CourseStatsData
    }
  }
  ${COURSE_DATA}
  ${PARTICIPANT_DATA}
  ${COURSE_STATS_DATA}
`;

export const COURSE_FULL_DATA = gql`
  fragment CourseFullData on Course {
    ...CourseData
    chapters {
      ...ChapterData
      questions {
        ...QuestionData
        studyStats {
          ...StudyStatsData
        }
      }
    }
    questions {
      ...QuestionData
      studyStats {
        ...StudyStatsData
      }
    }
    courseStats {
      ...CourseStatsData
    }
  }
  ${COURSE_DATA}
  ${CHAPTER_DATA}
  ${QUESTION_DATA}
  ${STUDY_STATS_DATA}
  ${COURSE_STATS_DATA}
`;

export const CHAPTER_FULL_DATA = gql`
  fragment ChapterFullData on Chapter {
    ...ChapterData
    questions {
      ...QuestionData
      studyStats {
        ...StudyStatsData
      }
    }
  }
  ${CHAPTER_DATA}
  ${QUESTION_DATA}
  ${STUDY_STATS_DATA}
`;

export const GROUP_FULL_DATA = gql`
  fragment GroupFullData on Group {
    ...GroupData
    students {
      ...ParticipantData
    }
  }
  ${GROUP_DATA}
  ${PARTICIPANT_DATA}
`;

export const GAME_FULL_DATA = gql`
  fragment GameFullData on Game {
    ...GameData
    playStats {
      ...PlayStatsData
    }
  }
  ${GAME_DATA}
  ${PLAY_STATS_DATA}
`;

export const PLAY_RESPONSE_DATA = gql`
  fragment PlayResponseData on PlayResponse {
    id
    correction
  }
`;

export const STUDY_RESPONSE_DATA = gql`
  fragment StudyResponseData on StudyResponse {
    id
    studyAnswerId
    correction
    studyStats {
      ...StudyStatsData
    }
  }
  ${STUDY_STATS_DATA}
`;

export const TEST_SESSION_DATA = gql`
  fragment TestSessionData on TestSession {
    id
    accountId
    courseId
    startTime
    stopTime
    firstName
    lastName
    picture
  }
`;

export const TEST_RESPONSE_DATA = gql`
  fragment TestResponseData on TestResponse {
    id
    correction
    remainingTime
  }
`;

export const NOTIFICATION_DATA = gql`
  fragment NotificationData on Notification {
    id
    accountId
    type
    isRead
    metadata
    deliverAt
  }
`;

export const SSO_DATA = gql`
  fragment SSOData on SSO {
    id
    name
    specifier
    type
  }
`;

export const WOOGEN_DATA = gql`
  fragment WoogenData on WoogenConnection {
    token
  }
`;
