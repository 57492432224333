import classNames from 'classnames/bind';
import React from 'react';

import imageLogo from './images/logo.svg';
import styles from './SplashScreen.module.scss';

const cx = classNames.bind(styles);

const SplashScreen = () => (
  <div aria-hidden="true" className={cx('container')}>
    <img alt="" aria-hidden="true" className={cx('image')} src={imageLogo} />
  </div>
);

export default SplashScreen;
