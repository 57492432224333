import { ApolloClient, ApolloLink, concat } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import storeJS from 'store';

import i18n from 'lib/i18n';

import cache from './cache';

const authMiddleware = new ApolloLink((operation, forward) => {
  const language = i18n.language || 'en';
  const fetchOptions = {
    headers: {
      'X-Language': language,
    },
  };
  const token = storeJS.get('token');
  if (token) {
    fetchOptions.headers.Authorization = `Bearer ${token}`;
  }
  operation.setContext(fetchOptions);
  return forward(operation);
});

const client = new ApolloClient({
  cache,
  link: concat(
    authMiddleware,
    createUploadLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
    }),
  ),
});

export default client;
