import { gql, useQuery } from '@apollo/client';

import { ME_FULL_DATA } from 'gql/fragments';

export const QUERY_ME = gql`
  {
    me {
      ...MeFullData
    }
  }
  ${ME_FULL_DATA}
`;

export function useQueryMe(options) {
  const { loading, data, ...props } = useQuery(QUERY_ME, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
