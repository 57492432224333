import { ApolloProvider } from '@apollo/client';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from 'components/App';
import SplashScreen from 'components/Shared/SplashScreen';
import apollo from 'gql';
import 'lib/dayjs';
import 'lib/i18n';
import 'lib/store';

import 'styles/reboot.scss';
import 'styles/global.scss';

import './vendors';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<SplashScreen />}>
      <ApolloProvider client={apollo}>
        <App />
      </ApolloProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// serviceWorkerRegistration.register();
