/**
 * Track an event with data
 * @param {String} category the category of the event
 * @param {String} action the action of the event
 * @param {Object} data the data
 */
export const trackEvent = async (category, action, data = {}) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log('Log', category, action, data);
  }
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'event',
      eventCategory: category,
      eventAction: action,
      error: false,
      ...data,
    });
  }
};

/**
 * Report an error
 * @param {String} category the category of the event
 * @param {String} action the action of the event
 * @param {Object} err the error
 */
export const reportError = async (category, action, err) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log('ERROR', category, action, err);
  }
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'event',
      eventCategory: category,
      eventAction: action,
    });
  }
  if (window.Sentry) {
    window.Sentry.withScope((scope) => {
      scope.setTag(category, action);
      window.Sentry.captureException(err);
    });
  }
};
